<script>
import { mapGetters } from 'vuex'

import LangSwitcher from './LangSwitcher.vue'
import FeedbackModal from './feedback/FeedbackModal.vue'

export default {
    components: { FeedbackModal, LangSwitcher },
    computed: {
        ...mapGetters({
            is_whitelabel_mode: 'is_whitelabel_mode',
            disable_report_problem: 'disable_report_problem',
            to_terms_url: 'project/to_terms_url',
            to_privacy_url: 'project/to_privacy_url',
            cancelation_url: 'cancelation_url',
        }),
    },
}
</script>

<template>
    <div class="border-t border-primary--border w-full bg-white">
        <div
            class="flex items-start max-w-[1800px] mx-auto px-4 py-4 justify-between md:pb-4 md:px-16 md:items-center"
        >
            <div class="flex w-full flex-col md:items-center md:flex-row">
                <div
                    class="flex flex-1 items-center -mx-4 md:mt-0 md:mx-0 md:justify-between text-secondary--text flex-wrap order-3 md:order-1"
                >
                    <div v-if="!is_whitelabel_mode" class="flex items-center md:-mx-4">
                        <div class="text-xs my-2 hidden md:block" style="min-width: 150px">
                            © Copyright
                            <a class="text-text--link hover:underline" href="https://kinchaku.com">
                                Kinchaku
                            </a>
                        </div>
                        <KButton
                            href="https://help.kinchaku.com/category/226-category"
                            mode="text"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank"
                        >
                            {{ $t('manual') }}
                        </KButton>
                    </div>
                    <div
                        v-if="
                            is_whitelabel_mode &&
                            (to_terms_url || to_privacy_url || cancelation_url)
                        "
                        class="flex items-center md:-mx-4"
                    >
                        <KButton
                            v-if="to_privacy_url"
                            v-bind="to_privacy_url"
                            mode="text"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank"
                        >
                            {{ $t('privacy policy') }}
                        </KButton>
                        <KButton
                            v-if="to_terms_url"
                            v-bind="to_terms_url"
                            mode="text"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank"
                        >
                            {{ $t('terms and conditions') }}
                        </KButton>
                        <KButton
                            v-if="cancelation_url"
                            :href="cancelation_url"
                            mode="text"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank"
                        >
                            {{ $t('cancelation policy') }}
                        </KButton>
                    </div>
                </div>
                <div class="md:mx-4 md:px-8 md:border-r md:order-2">
                    <FeedbackModal v-if="!is_whitelabel_mode && !disable_report_problem">
                        <template #trigger="{ open }">
                            <KButton
                                @click="open"
                                iconLeft="WrenchScrewdriverIcon"
                                mode="secondary"
                                size="M"
                            >
                                {{ $t('Report a problem') }}
                            </KButton>
                        </template>
                    </FeedbackModal>
                </div>
            </div>
            <div class="-mr-4 -my-2">
                <LangSwitcher />
            </div>
        </div>
    </div>
</template>
